import React, { useEffect } from 'react';

const ShadowDOMExample = () => {
  useEffect(() => {
    const shadowHost = document.getElementById('shadow-host');

    if (!shadowHost.shadowRoot) {
      const shadowRoot = shadowHost.attachShadow({ mode: 'open' });

      const shadowContent = `
        <div>
          <input id="shadow-input" type="text" placeholder="Enter text here" />
          <button id="shadow-button">Click Me</button>
        </div>
      `;

      shadowRoot.innerHTML = shadowContent;

      const shadowButton = shadowRoot.getElementById('shadow-button');
      const shadowInput = shadowRoot.getElementById('shadow-input');

      shadowButton.addEventListener('click', () => {
        alert(`Button clicked! Input value: ${shadowInput.value}`);
      });
    }
  }, []);

  return (
    <div>
      <div id="shadow-host"></div>
    </div>
  );
};

export default ShadowDOMExample;
