import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Shopping from './pages/Shopping';
import About from './pages/About';
import Contact from './pages/Contact';
import WebElements from './pages/WebElements';
import Checkboxes from './pages/webelements/Checkboxes';
import FormButtonElements from './pages/webelements/FormButtonElements';
import DragDropPage from './pages/webelements/DragDrop';
import HoverToClickButtonPage from './pages/webelements/HoverToClickButton';
import FileDownloader from './pages/webelements/FileDownLoader';
import ApiPage from './pages/ApiPage'; 
import SliderPage from './pages/webelements/SliderPage';
import GeolocationPage from './pages/webelements/GeolocationPage'; // Correct import path
import Login from './pages/webelements/Login';
import AlertPage from './pages/webelements/AlertPage'; 
import NestedFramePage from './pages/webelements/NestedFramePage';
import RadioButtonPage from './pages/webelements/RadioButtonPage';
import SelectBoxPage from './pages/webelements/SelectBoxPage';
import NewTabOrWindowPage from './pages/webelements/NewTabOrWindowPage';
import ShadowDOMPage from './pages/webelements/ShadowDOMPage';
import { AppProvider, useAppContext } from './AppContext';
import KeyPressesPage from './pages/webelements/KeyPressesPage'; 
import EditableTablePage from './pages/webelements/EditableTablePage';
import ScrollButtonPage from './pages/webelements/ScrollButtonPage';
import LoginBasicAuthResultPage from './pages/webelements/LoginBasicAuthResultPage';
import Checkout from './pages/Checkout';
import './styles/App.css';
import { CartProvider } from './CartContext';
import ProductDetails from './pages/ProductDetails';




const ProtectedRoute = ({ element }) => {
  const { isAuthenticated } = useAppContext();
  return isAuthenticated ? element : <Navigate to="/webelements/login" />;
};

function App() {
  return (
    <AppProvider>
       <CartProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
         <Route path="/shopping" element={<Shopping />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/webelements" element={<WebElements />} />
          <Route path="/api" element={<ApiPage />} /> {/* Add this line */}
          <Route path="/webelements/checkboxes" element={<Checkboxes />} />
          <Route path="/webelements/dragdrop" element={<DragDropPage />} />
          <Route path="/webelements/hovertoclickbutton" element={<HoverToClickButtonPage />} />
          <Route path="/webelements/slider" element={<SliderPage />} />
          <Route path="/webelements/filedownloader" element={<FileDownloader />} />
          <Route path="/webelements/formbuttonelements" element={<FormButtonElements />} />
          <Route path="/webelements/geolocationpage" element={<GeolocationPage />} />
          <Route path="/webelements/alertpage" element={<AlertPage/>} />
          <Route path="/webelements/keypressespage" element={<KeyPressesPage />} />
          <Route path="/webelements/nestedframepage" element={<NestedFramePage/>} />
          <Route path="/webelements/radiobuttonpage" element={<RadioButtonPage/>} />
          <Route path="/webelements/SelectBoxPage" element={<SelectBoxPage/>} />
          <Route path="/webelements/NewTabOrWindowPage" element={<NewTabOrWindowPage/>} />
          <Route path="/webelements/shadowdompage" element={<ShadowDOMPage />} />
          <Route path="/webelements/EditableTablePage" element={<EditableTablePage />} />
          <Route path="/webelements/ScrollButtonPage" element={<ScrollButtonPage />} />
          <Route path="/webelements/LoginBasicAuthResultPage" element={<ProtectedRoute element={<LoginBasicAuthResultPage />} />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/webelements/login" element={<Login />} />
          <Route path="/checkout" element={<Checkout />} />
        </Routes>
      </Router>
      </CartProvider>
    </AppProvider>
  );
}

export default App;
