import React from 'react';
import { useCart } from '../CartContext';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './Checkout.css'; // Make sure to create this CSS file
import { Helmet } from 'react-helmet';

const Checkout = () => {
  const { cart, dispatch } = useCart();
  const navigate = useNavigate();

  const handleCheckout = () => {
    dispatch({ type: 'CLEAR_CART' });
    alert('Thank you for your purchase!');
    navigate('/');
  };

  const handleRemoveItem = (index) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: { index } });
  };

  return (
    <main>
      <Helmet>
      <title>Checkout - Practice Automated Testing Checkout page</title>
      <link rel="canonical" href="https://practiceautomatedtesting.com/checkout" />
    </Helmet>
      <h1>Checkout Page</h1>
      <div className="cart-container">
        <table className="cart-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Product</th>
              <th>Price</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {cart.map((item, index) => (
              <tr key={index} className="cart-item">
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>${item.price}</td>
                <td>
                  <button onClick={() => handleRemoveItem(index)} className="delete-button">
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button onClick={handleCheckout} className="checkout-button">Checkout</button>
    </main>
  );
};

export default Checkout;
