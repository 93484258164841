import React from 'react';
import RadioButtons from '../../components/RadioButtons';

const RadioButtonPage = () => {
  return (
    <main>
      <RadioButtons />
    </main>
  );
};

export default RadioButtonPage;
