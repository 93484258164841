import React from 'react';
import Alerts from '../../components/Alerts';

const AlertPage = () => {
  return (
    <main>
      <h1>Web Elements</h1>
      <p>This is the web elements page.</p>
      <Alerts />
    </main>
  );
};

export default AlertPage;