import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const setAuth = (authStatus) => {
    setIsAuthenticated(authStatus);
  };

  return (
    <AppContext.Provider value={{ isAuthenticated, setAuth }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
