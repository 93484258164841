import React, { useState, useEffect } from 'react';
import styles from './HoverToClickButton.module.css'; // Import the CSS module

const HoverToClickButton = () => {
  const [hovered, setHovered] = useState(false);
  const [clickable, setClickable] = useState(false);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    let timer;
    if (hovered) {
      timer = setTimeout(() => {
        setClickable(true);
      }, 20000); // 20 seconds
    } else {
      clearTimeout(timer);
      setClickable(false);
    }
    return () => clearTimeout(timer);
  }, [hovered]);

  const handleClick = () => {
    if (clickable) {
      setClicked(true);
      setTimeout(() => {
        setClicked(false);
      }, 3000); // Show success message for 3 seconds
    }
  };

  return (
    <div className={styles.container}>
      <h1>Hover to Reveal Button</h1>
      <div>
        <p
          className={styles.hoverText}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          Hover over this text to reveal the button!
        </p>
        <button
          className={styles.hiddenButton}
          disabled={!clickable}
          onClick={handleClick}
        >
          Click Me
        </button>
      </div>
      {clicked && <div className={styles.successMessage}>Success!</div>}
    </div>
  );
};

export default HoverToClickButton;
