import React, { useState } from 'react';
import './EditableTable.module.css';

const EditableTable = () => {
  const initialData = Array(8).fill(Array(4).fill(''));
  const [tableData, setTableData] = useState(initialData);

  const handleChange = (rowIndex, colIndex, value) => {
    const newData = tableData.map((row, rIndex) => {
      if (rIndex === rowIndex) {
        return row.map((cell, cIndex) => (cIndex === colIndex ? value : cell));
      }
      return row;
    });
    setTableData(newData);
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Row</th>
            <th>Column 1</th>
            <th>Column 2</th>
            <th>Column 3</th>
            <th>Column 4</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>{rowIndex + 1}</td>
              {row.map((cell, colIndex) => (
                <td key={colIndex}>
                  <input
                    type="text"
                    value={cell}
                    onChange={(e) =>
                      handleChange(rowIndex, colIndex, e.target.value)
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EditableTable;
