import React from 'react';

const NewTabOrWindow = () => {
  const openNewTab = () => {
    window.open('https://learnautomatedtesting.com/', '_blank');
  };

  const openNewWindow = () => {
    window.open(
      'https://learnautomatedtesting.com/',
      'NewWindow',
      'width=800,height=600'
    );
  };

  return (
    <div>
      <h1>Open Link in New Tab or New Window</h1>
      <button onClick={openNewTab}>Open New Tab</button>
      <button onClick={openNewWindow}>Open New Window</button>
    </div>
  );
};

export default NewTabOrWindow;
