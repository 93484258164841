import React from 'react';
import NestedFrames from '../../components/NestedFrames';

const NestedFrame = () => {
  return (
    <main>
      <NestedFrames />
    </main>
  );
};

export default NestedFrame;