import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket, faBars } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../CartContext';
import { useAppContext } from '../AppContext';
import logo from '../practiceautomatedtestinglogo.webp';
import './Header.css';

const Header = () => {
  const { isAuthenticated, setAuth } = useAppContext();
  const { cart } = useCart();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLogout = () => {
    setAuth(false);
    navigate('/webelements/login');  // Updated path
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <nav className="nav">
        <div className="nav-left">
          <img src={logo} alt="Practice Automated Testing" className="site-icon" />
          <button className="hamburger" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
        <ul className={`nav-list ${menuOpen ? 'open' : ''}`}>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/webelements">Web Elements</Link></li>
          <li><Link to="/api">Api example</Link></li>
          <li><Link to="/shopping">Shopping</Link></li>
          <li>
            <Link to="/checkout">
              <FontAwesomeIcon icon={faShoppingBasket} />
              {cart.length > 0 && <span className="cart-count">{cart.length}</span>}
            </Link>
          </li>
          {isAuthenticated ? (
            <li><button onClick={handleLogout}>Logout</button></li>
          ) : (
            <li><Link to="/webelements/login">Login</Link></li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
