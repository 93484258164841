import React from 'react';
import SelectBox from '../../components/SelectBox';

const SelectBoxPage = () => {
  return (
    <main>
      <SelectBox />
    </main>
  );
};

export default SelectBoxPage;
