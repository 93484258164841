import React, { useState } from 'react';
import styles from './Slider.module.css';

const Slider = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className={styles.container}>
      <h1>Horizontal Slider</h1>
      <input
        type="range"
        min="0"
        max="5"
        step="0.5"
        value={value}
        onChange={handleChange}
        className={styles.slider}
      />
      <div className={styles.value}>{value}</div>
    </div>
  );
};

export default Slider;
