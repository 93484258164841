import React, { useEffect } from 'react';
import styles from './NestedFrames.module.css';

const NestedFrames = () => {
  useEffect(() => {
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach(iframe => {
      iframe.contentWindow.document.body.onmouseover = () => {
        iframe.style.backgroundColor = '#E7F3FF';
      };
      iframe.contentWindow.document.body.onmouseout = () => {
        iframe.style.backgroundColor = 'white';
      };
    });
  }, []);

  return (
    <div className={styles.frameContainer}>
      <iframe title="top" className={styles.frame} srcDoc="<html><body style='height:100vh;display:flex;justify-content:center;align-items:center;'>Top Frame</body></html>"></iframe>
      <div className={styles.frameRow}>
        <iframe title="left" className={styles.frame} srcDoc="<html><body style='height:100vh;display:flex;justify-content:center;align-items:center;'>Left Frame</body></html>"></iframe>
        <iframe title="middle" className={styles.frame} srcDoc="<html><body style='height:100vh;display:flex;justify-content:center;align-items:center;'>Middle Frame</body></html>"></iframe>
        <iframe title="right" className={styles.frame} srcDoc="<html><body style='height:100vh;display:flex;justify-content:center;align-items:center;'>Right Frame</body></html>"></iframe>
      </div>
      <iframe title="bottom" className={styles.frame} srcDoc="<html><body style='height:100vh;display:flex;justify-content:center;align-items:center;'>Bottom Frame</body></html>"></iframe>
    </div>
  );
};

export default NestedFrames;
