import React from 'react';
import ShadowDOMExample from '../../components/ShadowDOMExample';

const ShadowDOMPage = () => {
  return (
    <main>
      <ShadowDOMExample />
    </main>
  );
};

export default ShadowDOMPage;
