import React, { useState } from 'react';
import styles from './SelectBox.module.css';

const SelectBox = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [message, setMessage] = useState('');

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    setMessage(`You selected: ${value}`);
    setTimeout(() => {
      setMessage('');
    }, 3000); // Hide the message after 3 seconds
  };

  return (
    <div className={styles.container}>
      <h1>Select Box</h1>
      <p>Work Instruction: Use the following select box for testing.</p>
      <section className={styles.section}>
        <select value={selectedOption} onChange={handleSelectChange}>
          <option value="">--Select an option--</option>
          <option value="Option 1">Option 1</option>
          <option value="Option 2">Option 2</option>
          <option value="Option 3">Option 3</option>
        </select>
      </section>
      {message && (
        <div className={`${styles.message} ${styles.show}`}>
          {message}
        </div>
      )}
    </div>
  );
};

export default SelectBox;
