import React, { useState } from 'react';

const InputAndButtonElements = () => {
  const [showAnimation, setShowAnimation] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const age = document.getElementById('age').value;

    if (name && email && age) {
      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
      }, 5000); // Hide the animation after 5 seconds
    }
  };

  return (
    <main>
      <h1>Input and Button Elements</h1>
      <p>Work Instruction: Use the following input fields and buttons for testing.</p>
      <section>
        <form id="testForm" onSubmit={handleSubmit}>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" required /><br /><br />
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" required /><br /><br />
          <label htmlFor="age">Age:</label>
          <input type="number" id="age" name="age" required /><br /><br />
          <button type="submit" id="submitButton">Submit</button>
          <button type="reset" id="resetButton">Reset</button>
        </form>
        {showAnimation && (
          <div id="animation" className="animation">
            All fields were filled successfully!
          </div>
        )}
      </section>
    </main>
  );
};

export default InputAndButtonElements;
