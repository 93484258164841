import React from 'react';
import HoverToClickButton from '../../components/HoverToClickButton';

const HoverToClickButtonPage = () => {
  return (
    <main>
      <h1>Hover to Reveal Button</h1>
      <HoverToClickButton />
    </main>
  );
};

export default HoverToClickButtonPage;
