import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCart } from '../CartContext';
import styles from './ProductDetails.module.css';
import { Helmet } from 'react-helmet';

const ProductDetail = () => {
  const location = useLocation();
  const { product } = location.state;
  const { dispatch } = useCart();
  const navigate = useNavigate();
  const productDetailRef = useRef(null);
  const [relatedProducts, setRelatedProducts] = useState([]);

  useEffect(() => {
    if (productDetailRef.current) {
      productDetailRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    async function fetchRelatedProducts() {
      try {
        const response = await fetch('https://3nfndbmy3f.execute-api.eu-west-2.amazonaws.com/prod/products');
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        const products = await response.json();
        const filteredProducts = products.filter(p => p.id !== product.id);
        setRelatedProducts(filteredProducts.sort(() => 0.5 - Math.random()).slice(0, 4));
      } catch (error) {
        console.error('Failed to fetch related products:', error);
      }
    }

    fetchRelatedProducts();
  }, [product]);

  const addToCart = (product) => {
    dispatch({ type: 'ADD_TO_CART', payload: product });
  };

  return (
    <main>
      <Helmet>
        <title>{product.name} - Practice Automated Testing</title>
        <link rel="canonical" href={`https://practiceautomatedtesting.com/product/${product.id}`} />
      </Helmet>
      <div ref={productDetailRef}>
        <h1>Product Details</h1>
        <div className={styles.productDetailsContainer}>
          <img src="https://picsum.photos/1800" alt="Product" className={styles.productImageLarge} />
          <div className={styles.productDetailsInfo}>
            <h2>{product.name}</h2>
            <p className={styles.longDescription}>{product.longDescription}</p>
            <h3>Description of the product</h3>
            <p className={styles.longDescription}>{product.longdescriptionproductitem}</p>
            <p>Price: ${product.price}</p>
            <button onClick={() => addToCart(product)} className={styles.addToCartBtn}>Add to Cart</button>
          </div>
        </div>
      </div>
      <h2>Related Products</h2>
      <div className={styles.productsContainer}>
        {relatedProducts.map(relatedProduct => (
          <div
            key={relatedProduct.id}
            className={styles.shoppingCard}
            onClick={() => {
              navigate(`/product/${relatedProduct.id}`, { state: { product: relatedProduct } });
              window.scrollTo(0, 0); // Scroll to the top of the page when a related product is clicked
            }}
          >
            <img src="https://picsum.photos/1800" alt="Related Product" className={styles.productImage} />
            <div className={styles.productInfo}>
              <h2 className={styles.productTitle}>{relatedProduct.name}</h2>
              <p className={styles.productDescription}>{relatedProduct.description}</p>
              <p className={styles.productPrice}>${relatedProduct.price}</p>
            </div>
          </div>
        ))}
      </div>
    </main>
  );
};

export default ProductDetail;
