import React from 'react';
import { Helmet } from 'react-helmet';

const Home = () => (
  <main>
    <Helmet>
      <title>Home - Practice Automated Testing</title>
      <link rel="canonical" href="https://practiceautomatedtesting.com/" />
    </Helmet>
    <h1>Welcome to Automated Testing Tutorials</h1>
    <section>
      <h2>Introduction</h2>
      <p>Welcome to our website dedicated to teaching you the essentials of automated testing using various tools. We offer comprehensive tutorials and examples for both beginners and advanced users.</p>
    </section>

    <section>
      <h2>Beginner Tutorials: Web Elements</h2>
      <p>If you're new to automated testing, start with our <a href="/webelements">Web Elements</a> section. Here, you'll learn about the common web elements used in Selenium and how to interact with them.</p>
    </section>

    <section>
      <h2>Advanced Tutorials: Shopping Application</h2>
      <p>Once you're comfortable with the basics, move on to our <a href="/shopping">Shopping Application</a> section. This advanced tutorial will guide you through placing orders, authentication, and more complex scenarios.</p>
    </section>

    <section>
      <h2>api testing</h2>
      <p>Explore the apis which you can use to practice for automated testing, <a href="/api">API automatedtesting(TODO)</a> Our courses cover a guided path on how to create automated tests in Postman, ReadyAPI, Parasoft via Apis and SOAP.</p>
    </section>


    <section>
      <h2>Courses and Guided Paths</h2>
      <p>Explore our detailed courses available on our learning portal, <a href="https://learnautomatedtesting.com">learnautomatedtesting.com</a>. Our courses cover a guided path on how to derive CSS, XPath, and writing your first Selenium or Playwright script.</p>
    </section>

   
  </main>
);

export default Home;