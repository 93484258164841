import React from 'react';
import LoginBasicAuthResult from '../../components/LoginBasicAuthResult';

const LoginBasicAuthResultPage = () => {
  return (
    <main>
      <LoginBasicAuthResult />
    </main>
  );
};

export default LoginBasicAuthResultPage;