import React, { useState } from 'react';


const DragDrop = () => {
  const [dragging, setDragging] = useState(false);

  const handleDragStart = (event) => {
    event.dataTransfer.setData('text/plain', event.target.innerHTML);
    setDragging(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.target.classList.add('drag-over');
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.target.innerHTML = '😊';
    event.target.classList.remove('drag-over');
    setDragging(false);
  };

  const handleDragEnd = (event) => {
    setDragging(false);
  };

  const handleReset = () => {
    document.querySelectorAll('.dropzone').forEach(dropzone => {
      dropzone.innerHTML = 'Drop Here';
    });
  };

  return (
    <main>
      <h1>Drag and Drop</h1>
      <p>Work Instruction: Use the following elements for drag and drop testing.</p>
      <section>
        <div className="drag-container">
          <div
            className={`draggable ${dragging ? 'dragging' : ''}`}
            draggable="true"
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            Drag Me
          </div>
          <div
            className="dropzone"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            Drop Here
          </div>
        </div>
        <p></p>
        <button className="reset-button" onClick={handleReset}>
          Reset
        </button>
      </section>
    </main>
  );
};

export default DragDrop;

