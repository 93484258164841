import React from 'react';
import EditableTable from '../../components/EditableTable';

function EditableTablePage() {
  return (
    <div className="App">
      <h1>Editable Table Example</h1>
      <EditableTable />
    </div>
  );
}

export default EditableTablePage;