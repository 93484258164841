import React from 'react';
import './ApiPage.module.css'; // Create this CSS file for custom styling

const ApiPage = () => {
  const exampleJson = {
    rate: "75",
    hours: "173",
    currency: "Eur",
    employer_contribution: "2000",
    income_related_contribution: "2000",
    leasecar: "37",
    travelcosts: "10",
    othercosts: "10",
    educationcosts: "150",
    failurerate: "0.12",
    pensioenreserve: "20",
    salary: "2000",
    percentage: "0.7"
  };

  return (
    <div className="api-page">
      <h1>API Interaction Example</h1>
      <p>Below is an example of how you can interact with the API using Postman. 
        It is an api for a Bonus Calculation if you want to become a freelancer but are not ready to do so according to the dutch system
      </p>
      <div className="example-json">
        <h2>Example JSON</h2>
        <pre>{JSON.stringify(exampleJson, null, 2)}</pre>
      </div>
      <div className="instructions">
        <h2>Instructions</h2>
        <p>To test the API with Postman, follow these steps:</p>
        <ol>
          <li>Open Postman and create a new request.</li>
          <li>Set the request method to <strong>POST</strong>.</li>
          <li>Set the request URL to <strong>https://api.practiceautomatedtesting.com/</strong>.</li>
          <li>Go to the <strong>Headers</strong> tab and add a new header:
            <ul>
              <li><strong>Key:</strong> Content-Type</li>
              <li><strong>Value:</strong> application/json</li>
            </ul>
          </li>
          <li>Go to the <strong>Body</strong> tab and select <strong>raw</strong> and <strong>JSON</strong>.</li>
          <li>Copy the example JSON from above and paste it into the body.</li>
          <li>Click the <strong>Send</strong> button to send the request.</li>
          <li>Check the response in the <strong>Postman</strong> response window.</li>
        </ol>
      </div>
    </div>
  );
};

export default ApiPage;
