import React from 'react';

const Contact = () => {
  return (
    <main>
      <h1>Contact Us</h1>
      <p>This is the contact page.</p>
    </main>
  );
};

export default Contact;