import React from 'react';
import FileDownloader from '../../components/FileDownloader';


const FileDownloaderPage = () => {
    return (
      <main>
        <FileDownloader />
      </main>
    );
  };

export default FileDownloaderPage;