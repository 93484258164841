import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../CartContext';
import ReactSlider from 'react-slider';
import styles from './Shopping.module.css';
import { Helmet } from 'react-helmet';

const Shopping = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [priceRange, setPriceRange] = useState([0, 1500]);
  const { dispatch } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchProducts() {
      try {
        const response = await fetch('https://3nfndbmy3f.execute-api.eu-west-2.amazonaws.com/prod/products');
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        const products = await response.json();
        setProducts(products);
      } catch (error) {
        console.error('Failed to fetch products:', error);
      }
    }

    fetchProducts();
  }, []);

  const addToCart = (product) => {
    dispatch({ type: 'ADD_TO_CART', payload: product });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePriceChange = (newValue) => {
    setPriceRange(newValue);
  };

  const filteredProducts = products.filter(product => {
    return (
      product.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      product.price >= priceRange[0] &&
      product.price <= priceRange[1]
    );
  });

  const viewProductDetails = (product) => {
    navigate(`/product/${product.id}`, { state: { product } });
  };

  return (
    <main>
      <Helmet>
        <title>Shopping - Practice Automated Testing</title>
        <link rel="canonical" href="https://practiceautomatedtesting.com/shopping" />
      </Helmet>
      <h1>Shopping Cart</h1>
      <div className={styles.content}>
        <div className={styles['filter-container']}>
          <input
            type="text"
            placeholder="Search products..."
            value={searchQuery}
            onChange={handleSearchChange}
            className={styles['search-box']}
          />
          <div className={styles['slider-container']}>
            <label>Price Range: </label>
            <ReactSlider
              className={styles['horizontal-slider']}
              thumbClassName={styles['example-thumb']}
              trackClassName={styles['example-track']}
              min={0}
              max={1500}
              defaultValue={[0, 1500]}
              ariaLabel={['Lower thumb', 'Upper thumb']}
              ariaValuetext={state => `Thumb value ${state.valueNow}`}
              pearling
              minDistance={10}
              value={priceRange}
              onChange={handlePriceChange}
            />
            <p />
            <span className={styles['price-range']}>${priceRange[0]} - ${priceRange[1]}</span>
          </div>
        </div>
        <div className={styles['products-container']}>
          {filteredProducts.map(product => (
            <div key={product.id} className={styles['shopping-card']}>
              <img
                src="https://picsum.photos/1800"
                alt="Product"
                className={styles['product-image']}
                onClick={() => viewProductDetails(product)}
              />
              <div className={styles['product-info']}>
                <h2 className={styles['product-title']}>{product.name}</h2>
                <p className={styles['product-description']}>{product.description}</p>
                <p className={styles['product-price']}>${product.price}</p>
                <button onClick={() => addToCart(product)} className={styles['add-to-cart-btn']}>Add to Cart</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
};

export default Shopping;
