import React, { useState } from 'react';
import './AnimatedCheckbox.css'; // Import the CSS file for styling

const AnimatedCheckbox = ({ id, label, angry }) => {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  return (
    <div className={`checkbox-container ${angry ? 'angry' : ''}`}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={handleCheckboxChange}
      />
      <label htmlFor={id} className={`checkbox-label ${checked ? 'checked' : ''}`}>
        <span className="icon">{checked ? (angry ? '😠' : '😊') : ''}</span>
        {label}
      </label>
    </div>
  );
};

export default AnimatedCheckbox;
