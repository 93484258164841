import React, { useState, useEffect } from 'react';
import styles from './KeyPresses.module.css';

const KeyPresses = () => {
  const [key, setKey] = useState('');

  const handleKeyPress = (event) => {
    setKey(event.key);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <div className={styles.container}>
      <h1>Key Presses</h1>
      <input className={styles.inputBox} type="text" placeholder="Click here and press any key" />
      {key && <div className={styles.keyPressed}>You pressed: {key}</div>}
    </div>
  );
};

export default KeyPresses;
