import React from 'react';
import AnimatedCheckbox from '../../components/AnimatedCheckbox';

const Checkboxes = () => {
  return (
    <main>
      <h1>Checkboxes</h1>
      <p>Work Instruction: Use the following checkboxes for testing.</p>
      <section>
        <AnimatedCheckbox id="checkbox1" label="Checkbox 1" />
        <AnimatedCheckbox id="checkbox2" label="Checkbox 2" angry />
      </section>
    </main>
  );
};

export default Checkboxes;