// src/pages/webelements/LoginBasicAuthResult.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext'; // Corrected path
import styles from './LoginBasicAuthResult.module.css'; // Ensure you have some basic CSS for styling

const LoginBasicAuthResultPage = () => {
  const { setAuth } = useAppContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    setAuth(false);
    navigate('/webelements/loginbasicauth');
  };

  return (
    <div className={styles.container}>
      <h1>I am logged in</h1>
      <button onClick={handleLogout} className={styles.logoutButton}>Logout</button>
    </div>
  );
};

export default LoginBasicAuthResultPage;
