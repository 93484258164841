import React from 'react';
import NewTabOrWindow from '../../components/NewTaborWindows';

const NewTabOrWindowPage = () => {
  return (
    <main>
      <NewTabOrWindow />
    </main>
  );
};

export default NewTabOrWindowPage;
