import React from 'react';
import { Link } from 'react-router-dom';

const WebElements = () => (
  <main>
    <h1>Common Web Elements to be tested with Testautomation tools like Playwright, Selenium,Cypress, Katalon</h1>
    <nav>
      <ul>
        <li><Link to="/webelements/Checkboxes">Checkboxes Page</Link></li>
        <li><Link to="/webelements/RadioButtonPage">RadioButtons Page</Link></li>
        <li><Link to="/webelements/SelectBoxPage">SelectBox Page</Link></li>
        <li><Link to="/webelements/FormButtonElements">Input Elements Page</Link></li>
        <li><Link to="/webelements/DragDrop">DragDrop Page</Link></li>
        <li><Link to="/webelements/HoverToClickButton">Hover Page </Link></li>
        <li><Link to="/webelements/FileDownLoader">Filedownloader Page </Link></li>
        <li><Link to="/webelements/Slider">Slider </Link></li>
        <li><Link to="/webelements/LoginBasicAuthResultPage">Basic Auth validation Page </Link></li>
        <li><Link to="/webelements/GeolocationPage">Geolocations Page </Link></li>
        <li><Link to="/webelements/AlertPage">Alert Page </Link></li>
        <li><Link to="/webelements/NestedFramePage">NestedFrame Page </Link></li>
        <li><Link to="/webelements/KeyPressesPage">KeyPresses Page </Link></li>
        <li><Link to="/webelements/newtaborwindowpage">New tab or Windowpage </Link></li>
        <li><Link to="/webelements/shadowdompage">ShadowDom page </Link></li>
        <li><Link to="/webelements/editabletablepage">Editable Table page </Link></li>
        <li><Link to="/webelements/ScrollButtonPage">Scroll To Button </Link></li>
      </ul>
    </nav>
  </main>
);

export default WebElements;