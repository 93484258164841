import React, { useState } from 'react';
import styles from './RadioButtons.module.css';

const RadioButtons = () => {
  const [message, setMessage] = useState('');

  const handleRadioClick = (event) => {
    const value = event.target.value;
    setMessage(`You selected: ${value}`);
    setTimeout(() => {
      setMessage('');
    }, 3000); // Hide the message after 3 seconds
  };

  return (
    <main>
      <h1>Radio Buttons</h1>
      <p>Work Instruction: Use the following radio buttons for testing.</p>
      <section className={styles.section}>
        <input
          type="radio"
          name="radioGroup"
          id="radio1"
          value="Radio 1"
          onClick={handleRadioClick}
        />{' '}
        Radio 1
        <br />
        <input
          type="radio"
          name="radioGroup"
          id="radio2"
          value="Radio 2"
          onClick={handleRadioClick}
        />{' '}
        Radio 2
        <br />
      </section>
      {message && <div className={`${styles.message} ${styles.show}`}>{message}</div>}
    </main>
  );
};

export default RadioButtons;
