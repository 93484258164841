import React, { useState } from 'react';
import './ScrollButton.module.css';

const ScrollButton = () => {
  const [message, setMessage] = useState('');

  const handleClick = () => {
    setMessage('Found and clicked on the button!');
  };

  return (
    <div className="scroll-container">
      <div className="content">
        {/* Simulate long content */}
        {Array(50).fill().map((_, i) => (
          <p key={i}>This is line {i + 1} of the long content area. Scroll down to find the button.</p>
        ))}
        <button className="scroll-button" onClick={handleClick}>Click Me</button>
      </div>
      {message && <div className="message">{message}</div>}
    </div>
  );
};

export default ScrollButton;
