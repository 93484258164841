import React from 'react';
import styles from './Alerts.module.css';

const Alerts = () => {
  const showAlert = () => {
    // eslint-disable-next-line no-restricted-globals
    alert("This is a JavaScript alert!");
  };

  const showConfirm = () => {
    // eslint-disable-next-line no-restricted-globals
    const userConfirmed = confirm("Do you confirm this action?");
    // eslint-disable-next-line no-restricted-globals
    alert(`User confirmed: ${userConfirmed}`);
  };

  const showPrompt = () => {
    // eslint-disable-next-line no-restricted-globals
    const userInput = prompt("Please enter some text:");
    // eslint-disable-next-line no-restricted-globals
    alert(`User input: ${userInput}`);
  };

  return (
    <div className={styles.alertContainer}>
      <button className={styles.alertButton} onClick={showAlert}>Show Alert</button>
      <button className={styles.alertButton} onClick={showConfirm}>Show Confirm</button>
      <button className={styles.alertButton} onClick={showPrompt}>Show Prompt</button>
    </div>
  );
};

export default Alerts;
