import React from 'react';
import ScrollButton from '../../components/ScrollButton';

const ScrollButtonPage = () => {
  return (
    <main>
      <ScrollButton />
    </main>
  );
};

export default ScrollButtonPage;
