import React from 'react';
import Keypresses from '../../components/KeyPresses';

const KeyPressesPage = () => {
  return (
    <main>
  
      <Keypresses />
    </main>
  );
};

export default KeyPressesPage;