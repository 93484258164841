import React from 'react';
import Geolocation from '../../components/Geolocations';

const GeolocationPage = () => {
  return (
    <main>
      <Geolocation />
    </main>
  );
};

export default GeolocationPage;
