import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import styles from './FileDownloader.module.css';

const initialFiles = [
  { name: 'Example PDF', url: '/files/example.pdf' },
  { name: 'Example Excel', url: '/files/example.xlsx' },
];

const FileDownloader = () => {
  const [files] = useState(initialFiles);

  const handleDownload = async (file) => {
    try {
      const response = await fetch(file.url, {
        headers: new Headers({
          'Content-Type': 'application/octet-stream'
        })
      });
      const blob = await response.blob();
      saveAs(blob, file.name);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <div className={styles.container}>
      <h1>File Downloader</h1>
      <ul className={styles.fileList}>
        {files.map((file, index) => (
          <li key={index} className={styles.fileItem}>
            <button
              onClick={() => handleDownload(file)}
              className={styles.downloadButton}
            >
              Download
            </button>
            {file.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FileDownloader;
