import React from 'react';

const About = () => {
  return (
    <main>
      <h1>About Us</h1>
      <p>This is the about page.</p>
    </main>
  );
};

export default About;
